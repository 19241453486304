import {createTheme, ThemeProvider} from "@mui/material/styles";
import {
  Box,
  Button,
  Chip,
  CircularProgress, Divider,
  Stack,
  TextField
} from "@mui/material";
import Grid from "@mui/material/Grid";
import {Fragment, useEffect, useState} from "react";
import validator from "validator";
import {Link, useParams} from "react-router-dom";
import logo from "./pecokt.png";

const baseUrl = "https://dev-api.caroot.io";
//const baseUrl = "http://localhost:3000";
const promptBaseUrl = "https://dev-api.caroot.io/api/v1/caroot-knowledge-management"
//const promptBaseUrl = "http://localhost:3002/api/v1/caroot-knowledge-management";

const theme = createTheme({
  palette: {
    primary: {
      main: '#3949ab',
    },
  },
});

function isEmpty(obj) {
  return obj == null || Object.keys(obj).length === 0;
}

function ResponseView(props) {
  const {data = {}, loading, prompts, url} = props;
  const [tags, setTags] = useState([]);
  const [keyIdeas, setKeyIdeas] = useState("");
  const [keyIdeasPrompt, setKeyIdeasPrompt] = useState("");
  const [tagsPrompt, setTagsPrompt] = useState("");
  const [submittingKeyIdeasPrompt, setSubmittingKeyIdeasPrompt] = useState(false);
  const [submittingTagsPrompt, setSubmittingTagsPrompt] = useState(false);

  useEffect(() => {
    setTags(data["tags"] ?? []);
    setKeyIdeas(data["keyIdeas"] ?? "");
  }, [data]);

  useEffect(() => {
    setKeyIdeasPrompt(prompts["keyIdeas"]?.trim() ?? "");
    setTagsPrompt(prompts["tags"]?.trim() ?? "");
  }, [prompts]);

  if (isEmpty(data)) {
    if (loading) return (
      <Stack
        display="flex"
        justifyContent="center"
        alignItems="center"
        spacing={2}>
        <CircularProgress />
        <div>
          Our hard-working rabbits are reading your link to understand it. Please wait a bit!
        </div>
      </Stack>
    );

    return null;
  }

  const testPrompt = (mode) => {
    if (mode === 'keyIdeas') {
      setSubmittingKeyIdeasPrompt(true);
      setKeyIdeas("Waiting for response...");
    } else if (mode === 'tags') {
      setSubmittingTagsPrompt(true);
      setTags(["Loading..."]);
    }

    fetch(`${promptBaseUrl}/test-prompt`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        url, mode,
        prompt: mode === 'keyIdeas' ? keyIdeasPrompt : tagsPrompt,
      })
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        if (mode === 'keyIdeas') {
          setKeyIdeas(json["keyIdeas"]);
        } else if (mode === 'tags') {
          setTags(json["tags"]);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        if (mode === 'keyIdeas') {
          setSubmittingKeyIdeasPrompt(false);
        } else if (mode === 'tags') {
          setSubmittingTagsPrompt(false);
        }
      })
  }

  return (
    <Fragment>

      <Stack spacing={2} alignItems="flex-start">
        <div>Prompt for tags:</div>

        <TextField
          multiline
          //rows={8}
          maxRows={20}
          value={tagsPrompt}
          onChange={e => setTagsPrompt(e.target.value)}
          fullWidth
        />

        <Button
          variant="outlined"
          color={"primary"}
          disabled={submittingTagsPrompt}
          onClick={() => testPrompt("tags")}>
          Test
        </Button>

        <div>Tags for this content:</div>

        <Stack spacing={{ xs: 1, sm: 1 }}
               direction="row"
               flexWrap="wrap"
               useFlexGap
               marginTop={2} marginBottom={2} >
          {
            tags.map(tag => <Chip label={tag} key={tag} />)
          }
        </Stack>
      </Stack>

      <Divider/>

      <Stack spacing={2} alignItems="flex-start">
        <div>Prompt for key ideas:</div>

        <TextField
          multiline
          maxRows={20}
          value={keyIdeasPrompt}
          onChange={e => setKeyIdeasPrompt(e.target.value)}
          fullWidth
        />

        <Button
          variant="outlined"
          color={"primary"}
          disabled={submittingKeyIdeasPrompt}
          onClick={() => testPrompt("keyIdeas")}>
          Test
        </Button>

        <div>Key points (edit if necessary):</div>

        <TextField
          multiline
          rows={8}
          value={keyIdeas}
          onChange={e => setKeyIdeas(e.target.value)}
          fullWidth
          InputProps={{
            readOnly: true,
          }}
        />
      </Stack>
    </Fragment>
  )
}

function Prompt() {
  const {username} = useParams();
  
  const [urlInput, setUrlInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [data, setData] = useState({});
  const [url, setUrl] = useState("");
  const [urlId, setUrlId] = useState("");
  const [prompts, setPrompts] = useState({});

  const submit = (value) => {
    if (!value.startsWith("http")) {
      value = "https://" + value;
    }

    if (validator.isURL(value)) {
      setUrl("");
      setUrlId("");
      setError('');
      setLoading(true);
      setData({});

      fetch(`${baseUrl}/bookmark`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: username,
          url: urlInput,
        })
      })
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          setUrl(json["url"]);
          setUrlId(json["_id"]);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });

    } else {
      setError('URL is not valid');
    }
  };

  useEffect(() => {
    if (!urlId || !isEmpty(data)) return;

    const fetchOverrides = () => {
      fetch(`${baseUrl}/notepad/${urlId}`)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setData(data ?? {});
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    fetchOverrides();

    const interval = setInterval(() => {
      fetchOverrides();
    }, 5000);

    return () => clearInterval(interval);
  }, [urlId, data]);

  useEffect(() => {
    fetch(`${promptBaseUrl}/prompts`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setPrompts(data ?? {});
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  return (
    <ThemeProvider theme={theme}>
      <Box
        display="flex"
        justifyContent="center">
        <Link to={"/"}>
          <img src={logo} width={"200px"} alt={"Logo"}/>
        </Link>
      </Box>

      <Box
        display="flex"
        justifyContent="center">

        <Stack
          direction="column"
          marginTop={4}
          spacing={4}
          justifyContent="center"
          sx={{ width: "720px"}}>

          <Grid container
                direction={"row"}
                columnSpacing={2}>
            <Grid item xs={9}>
              <TextField
                id="url"
                fullWidth
                size="small"
                variant="outlined"
                placeholder={"https://google.com"}
                error={error.length > 0}
                helperText={error}
                onSubmit={() => submit(urlInput)}
                onChange={(e) => setUrlInput(e.target.value.trim())}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    submit(urlInput);
                    e.preventDefault();
                  }
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <Button
                variant="contained"
                color={"primary"}
                disabled={loading}
                onClick={() => submit(urlInput)}>
                Test Prompt
              </Button>
            </Grid>
          </Grid>

          <ResponseView data={data} prompts={prompts} loading={loading} url={url} />

          <div></div>
        </Stack>
      </Box>
    </ThemeProvider>
  )
}

export default Prompt;