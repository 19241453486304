import {createTheme, ThemeProvider} from "@mui/material/styles";
import {Box, Button, Stack, TextField} from "@mui/material";
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { Link } from "react-router-dom";
import {useEffect, useState} from "react";
import logo from "./pecokt.png";

const theme = createTheme({
  palette: {
    primary: {
      main: '#9c27b0',
    },
  },
});

function Home() {
  const [username, setUsername] = useState("");

  useEffect(() => {
    const username = localStorage.getItem('username');
    if (username) {
      setUsername(username);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh">

        <Stack
          display="flex"
          alignItems="center"
          spacing={2}>

          <img src={logo} width={"200px"} alt={"Logo"}/>

          <TextField
            sx={{ width: 150 }}
            variant="outlined"
            size="small"
            placeholder="Username"
            value={username}
            onChange={e => setUsername(e.target.value)}
          />

          <Stack direction="row" spacing={2}>
            <Link to={`/bookmarks/${username}`} >
              <Button
                variant="contained"
                color={"primary"}
                disabled={!username}
                onClick={() => localStorage.setItem('username', username)}
                endIcon={<BookmarksIcon />}>
                Bookmarks
              </Button>
            </Link>

            <Link to={`/notepad/${username}`} >
              <Button
                variant="contained"
                color={"primary"}
                disabled={!username}
                onClick={() => localStorage.setItem('username', username)}
                endIcon={<StickyNote2Icon />}>
                Notepad
              </Button>
            </Link>

            <Link to={`/prompt/${username}`} >
              <Button
                variant="contained"
                color={"primary"}
                disabled={!username}
                onClick={() => localStorage.setItem('username', username)}
                endIcon={<AutoFixHighIcon />}>
                Prompt
              </Button>
            </Link>
          </Stack>
        </Stack>
      </Box>

    </ThemeProvider>
  )
}

export default Home;