import {createTheme, ThemeProvider} from "@mui/material/styles";
import {Box, Button, Chip, CircularProgress, IconButton, Stack, TextField} from "@mui/material";
import Grid from "@mui/material/Grid";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {Fragment, useEffect, useState} from "react";
import validator from "validator";
import {Link, useParams} from "react-router-dom";
import logo from "./pecokt.png";

const baseUrl = "https://dev-api.caroot.io";
//const baseUrl = "http://localhost:3000";

const theme = createTheme({
  palette: {
    primary: {
      main: '#9c27b0',
    },
  },
});

function isEmpty(obj) {
  return obj == null || Object.keys(obj).length === 0;
}

function ResponseView(props) {
  const {data = {}, loading, urlId, setData} = props;
  const [tags, setTags] = useState([]);
  const [keyIdeas, setKeyIdeas] = useState("");
  const [newTag, setNewTag] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [tagsChanged, setTagsChanged] = useState(false);
  const [keyIdeasChanged, setKeyIdeasChanged] = useState(false);
  const formDirty = tagsChanged || keyIdeasChanged;

  useEffect(() => {
    setTags(data["tags"] ?? []);
    setKeyIdeas(data["keyIdeas"] ?? "");
  }, [data]);

  useEffect(() => {
    setTagsChanged(JSON.stringify(tags) !== JSON.stringify(data["tags"]));
    setKeyIdeasChanged(keyIdeas !== data["keyIdeas"]);
    // setEnabled(tagsChanged.current || keyIdeasChanged.current);
  }, [data, keyIdeas, tags]);

  if (isEmpty(data)) {
    if (loading) return (
      <Stack
        display="flex"
        justifyContent="center"
        alignItems="center"
        spacing={2}>
        <CircularProgress />
        <div>
          Our hard-working rabbits are reading your link to understand it. Please wait a bit!
        </div>
      </Stack>
    );

    return null;
  }

  const addNewTag = () => {
    setTags([...tags, newTag]);
    setNewTag("");
  };

  const submit = () => {
    if (!tagsChanged && !keyIdeasChanged) return;
    setSubmitting(true);

    fetch(`${baseUrl}/notepad/${urlId}`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...(tagsChanged && {tags: tags}),
        ...(keyIdeasChanged && {keyIdeas: keyIdeas}),
      })
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        setData({
          tags: json.tags ?? data.tags,
          keyIdeas: json.keyIdeas ?? data.keyIdeas,
        })
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setSubmitting(false))
  }

  return (
    <Fragment>
      <Box>
        Tags for this content:

        <Stack spacing={{ xs: 1, sm: 1 }}
               direction="row"
               flexWrap="wrap"
               useFlexGap
               marginTop={2} marginBottom={2} >
          {
            tags.map(tag =>
              <Chip label={tag}
                    key={tag}
                    onDelete={() => {
                      setTags(tags.filter(t => t !== tag));
                    }} />
            )
          }
        </Stack>

        <TextField
          id="add-chip-input"
          variant="outlined"
          sx={{ width: 150 }}
          placeholder="New tag"
          size="small"
          value={newTag}
          onChange={e => {
            setNewTag(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              addNewTag();
              e.preventDefault();
            }
          }}
        />

        <IconButton
          aria-label="add-tag"
          disabled={newTag === ""}
          onClick={addNewTag}>
          <AddCircleOutlineIcon />
        </IconButton>
      </Box>

      <Stack spacing={2} alignItems="flex-start">
        <div>Key points (edit if necessary):</div>

        <TextField
          multiline
          rows={8}
          value={keyIdeas}
          onChange={e => setKeyIdeas(e.target.value)}
          fullWidth
        />

        <Button
          variant={formDirty ? "contained" : "outlined"}
          color={"primary"}
          disabled={submitting}
          onClick={submit}>
          { submitting ? "Updating..." : (formDirty ? "Update" : "Edit above to update") }
        </Button>
      </Stack>
    </Fragment>
  )
}

function Notepad() {
  const {username} = useParams();
  
  const [urlInput, setUrlInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [data, setData] = useState({});
  const [urlId, setUrlId] = useState("");

  const submit = (value) => {
    if (!value.startsWith("http")) {
      value = "https://" + value;
    }

    if (validator.isURL(value)) {
      setUrlId("");
      setError('');
      setLoading(true);
      setData({});

      fetch(`${baseUrl}/bookmark`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: username,
          url: urlInput,
        })
      })
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          setUrlId(json["_id"]);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });

    } else {
      setError('URL is not valid');
    }
  };

  useEffect(() => {
    if (!urlId || !isEmpty(data)) return;

    const fetchOverrides = () => {
      fetch(`${baseUrl}/notepad/${urlId}`)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setData(data ?? {});
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    fetchOverrides();

    const interval = setInterval(() => {
      fetchOverrides();
    }, 5000);

    return () => clearInterval(interval);
  }, [urlId, data]);

  return (
    <ThemeProvider theme={theme}>
      <Box
        display="flex"
        justifyContent="center">
        <Link to={"/"}>
          <img src={logo} width={"200px"} alt={"Logo"}/>
        </Link>
      </Box>

      <Box
        display="flex"
        justifyContent="center">

        <Stack
          direction="column"
          marginTop={4}
          spacing={4}
          justifyContent="center"
          sx={{ width: "720px"}}>

          <Grid container
                direction={"row"}
                columnSpacing={2}>
            <Grid item xs={10}>
              <TextField
                id="url"
                fullWidth
                size="small"
                variant="outlined"
                placeholder={"https://google.com"}
                error={error.length > 0}
                helperText={error}
                onSubmit={() => submit(urlInput)}
                onChange={(e) => setUrlInput(e.target.value.trim())}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    submit(urlInput);
                    e.preventDefault();
                  }
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <Button
                variant="contained"
                color={"primary"}
                disabled={loading}
                onClick={() => submit(urlInput)}>
                Bookmark
              </Button>
            </Grid>
          </Grid>

          <ResponseView data={data} loading={loading} urlId={urlId} setData={setData} />

        </Stack>

      </Box>


    </ThemeProvider>
  )
}

export default Notepad;