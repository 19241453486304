import {TextField, Button, Box, CircularProgress, Stack} from "@mui/material";
import Grid from '@mui/material/Grid';
import {Fragment, useCallback, useEffect, useState} from "react";
import validator from 'validator';
import logo from "./pecokt.png";

import {ThemeProvider, createTheme} from '@mui/material/styles';
import ReactJson from "react-json-view";
import {Link, useParams} from "react-router-dom";

const theme = createTheme({
  palette: {
    primary: {
      main: '#9c27b0',
    },
  },
});
// function formatDiffSeconds(mils) {
//   return (mils / 1000.0).toFixed(2) + "s";
// }

const baseUrl = "https://dev-api.caroot.io";
//const baseUrl = "http://localhost:3000";

function Bookmarks() {
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [data, setData] = useState({});
  const [disabled, setDisabled] = useState(false);

  const {username} = useParams();

  const fetchBookmarks = useCallback(() => {
    if (!username) return;
    setLoading(true);

    fetch(`${baseUrl}/bookmarks/${username}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setData(data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [username]);

  const submit = (value) => {
    if (!value.startsWith("http")) {
      value = "https://" + value;
    }

    if (validator.isURL(value)) {
      setError('');
      setLoading(true);
      setDisabled(true);

      fetch(`${baseUrl}/bookmark`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: username,
          url: url,
        })
      })
        .then((response) => {
          return response.json();
        })
        .then(() => {
          fetchBookmarks();
        })
        .catch((err) => {
          console.log(err);
          //setCurrentUrl("");
        })
        .finally(() => {
          //setResponseTime(new Date() - start);
          setLoading(false);
          setDisabled(false);
        });

    } else {
      setError('URL is not valid');
      setData({});
    }
  };

  const responseView = () => {
    if (Object.keys(data).length === 0) {
      if (loading) return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center">
          <CircularProgress />
        </Box>
      )


      return null;
    }

    const onCopy = (copy) => {
      const container = document.createElement('textarea')
      const val = copy.src

      container.innerHTML = typeof(val) === 'string' ? val : JSON.stringify(
        val,
        null,
        '  '
      )

      document.body.appendChild(container)
      container.select()
      // copy the same quote-less value back to the clipboard
      document.execCommand('copy')
      document.body.removeChild(container)
    }

    return (
      <Fragment>
        <Grid item xs={12}>
          <ReactJson
            src={data}
            name={false}
            displayDataTypes={false}
            displayArrayKey={false}
            collapsed={2}
            iconStyle={"square"}
            enableClipboard={onCopy}
          />
        </Grid>
      </Fragment>
    )
  }

  useEffect(() => {
    const interval = setInterval(() => {
      fetchBookmarks()
    }, 5000);
    return () => clearInterval(interval);
  }, [username, fetchBookmarks]);

  useEffect(() => {
    if (!username) {
      setError("Username is empty");
      setDisabled(true);
    } else {
      fetchBookmarks();
    }
  }, [username, fetchBookmarks]);

  return (
    <ThemeProvider theme={theme} >
      <Box
        display="flex"
        justifyContent="center">
        <Link to={"/"}>
          <img src={logo} width={"200px"} alt={"Logo"}/>
        </Link>
      </Box>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center">

        <Stack
          direction="column"
          marginTop={4}
          spacing={4}
          sx={{ width: "720px"}}>

          <Grid container
                direction={"row"}
                spacing={2}>
            <Grid item xs={10}>
              <TextField
                id="url"
                fullWidth
                size={"small"}
                variant="outlined"
                error={error.length > 0}
                helperText={error}
                placeholder={"https://google.com"}
                disabled={disabled}
                onSubmit={() =>  submit(url)}
                onChange={(e) => setUrl(e.target.value.trim())}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    submit(url);
                    e.preventDefault();
                  }
                }}
              />
            </Grid>

            <Grid item xs={2}>
              <Button
                variant="contained"
                color={"primary"}
                onClick={() => submit(url)}
                disabled={disabled}>
                Bookmark
              </Button>
            </Grid>
          </Grid>

          { responseView() }

        </Stack>

      </Box>
    </ThemeProvider>

  );
}

export default Bookmarks;
